import React from 'react';

// This is Facebook's logo, you are not allowed to change its color
export const FacebookLogo = (
  <svg
    width="20"
    height="30"
    viewBox="0 0 10 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65 1.108C8.413 1.072 7.59 1 6.633 1c-2 0-3.374 1.244-3.374 3.525V6.49H1v2.668h2.258v6.84h2.71V9.16h2.25l.345-2.668H5.968V4.786c0-.766.204-1.298 1.293-1.298h1.39v-2.38z"
      fillRule="evenodd"
    />
  </svg>
);

// This is Google's logo, you are not allowed to change its color
export const GoogleLogo = (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M19.6 10.227c0-.709-.064-1.39-.182-2.045H10v3.868h5.382a4.6 4.6 0 01-1.996 3.018v2.51h3.232c1.891-1.742 2.982-4.305 2.982-7.35z"
        fill="#4D6479"
      />
      <path
        d="M10 20c2.7 0 4.964-.895 6.618-2.423l-3.232-2.509c-.895.6-2.04.955-3.386.955-2.605 0-4.81-1.76-5.595-4.123H1.064v2.59A9.996 9.996 0 0010 20z"
        fill="#4D6479"
      />
      <path
        d="M4.405 11.9c-.2-.6-.314-1.24-.314-1.9 0-.66.114-1.3.314-1.9V5.51H1.064A9.996 9.996 0 000 10c0 1.614.386 3.14 1.064 4.49l3.34-2.59z"
        fill="#4D6479"
      />
      <path
        d="M10 3.977c1.468 0 2.786.505 3.823 1.496l2.868-2.868C14.959.99 12.695 0 10 0 6.09 0 2.71 2.24 1.064 5.51l3.34 2.59C5.192 5.736 7.396 3.977 10 3.977z"
        fill="#4D6479"
      />
      <path d="M0 0h20v20H0z" />
    </g>
  </svg>
);

// This is Linkedin's logo, you are not allowed to change its color
export const LinkedinLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 22 22"
    version="1.1"
    viewBox="0 0 22 22"
    xmlSpace="preserve"
    width="20" height="20"
  >
    <circle cx="11" cy="11" r="11" fill="#007AB9" className="st0"></circle>
    <path
      fill="#F1F2F2"
      d="M17.6 11.9v4.5H15v-4.2c0-1.1-.4-1.8-1.3-1.8-.7 0-1.2.5-1.3 1-.1.2-.1.4-.1.6v4.4H9.5V8.5h2.6v1.1c.3-.5 1-1.3 2.4-1.3 1.8 0 3.1 1.2 3.1 3.6zM6.8 4.7c-.9 0-1.5.6-1.5 1.4 0 .8.6 1.4 1.5 1.4s1.5-.6 1.5-1.4c-.1-.8-.6-1.4-1.5-1.4zM5.5 16.4h2.6V8.5H5.5v7.9z"
      className="st1"
    ></path>
  </svg>
)
